<template>
    <el-dialog title="数据详情" v-model="dialogTableVisible" width="1100px" top="10vh">
        <el-table :data="gridData" style="width: 1100px;height:440px" :row-style="rowStyle">
            <el-table-column label="姓名" prop="realname" width="100">
            </el-table-column>
            <el-table-column label="手机号" prop="mobile">
            </el-table-column>
            <el-table-column label="身份证号" prop="idcard" width="150">
            </el-table-column>
            <el-table-column label="申请类型" prop="apply_type_text">
            </el-table-column>
            <el-table-column label="字号一" prop="apply_name_1">
                <template #default="scope">
                    <p>{{ scope.row.apply_name_1 || '/' }}</p>
                </template>
            </el-table-column>
            <el-table-column label="字号二" prop="apply_name_2">
                <template #default="scope">
                    <p>{{ scope.row.apply_name_2 || '/' }}</p>
                </template>
            </el-table-column>
            <el-table-column label="字号三" prop="apply_name_3">
                <template #default="scope">
                    <p>{{ scope.row.apply_name_3 || '/' }}</p>
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="status">
                <template #default="scope">
                    <p v-if="scope.row.status_value==2">{{ `${scope.row.status}(${scope.row.user.nickname})` }}</p>
                    <p v-else>{{ scope.row.status }}</p>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" width="100px">
                <template #default="scope">
                    <div class="pointer" :class="scope.row.status_value==0?'color':'sub_color'" @click="delRow(scope.row.id, scope.row.status_value)">删 除</div>
                </template>
            </el-table-column>
        </el-table>
        <div class="flex_cen mt_15">
            <el-pagination class="page" background :page-size="limit" :current-page="page" layout="prev, pager, next" :total="total" @current-change="load">
            </el-pagination>
        </div>
    </el-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialogTableVisible: false,
			gridData: [],
			page: 1,
			limit: 10,
			total: 0,
			finish: false,
			id: ''
		}
	},
	methods: {
		load(page) {
			this.page = page
			this.init()
		},
		init() {
			this.$axios({
				url: `/ent/v3/bussiness/batch/data`,
				method: 'GET',
				data: {
					import_id: this.id,
					page: this.page
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.total = res.data.total
					this.gridData = res.data.list
				}
			})
		},
		delRow(id, status) {
			if(status!=0)return
			this.$confirm("删除此条数据?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				this.$axios({
					url: `/ent/v3/bussiness/batch/data/${id}`,
					method: 'DELETE',
					data: {
						page: this.page
					},
					hideLoading: true
				}).then(res => {
					if (res.code === 200) {
						this.total = res.data.total
						this.gridData = res.data.list
						this.$parent.init()
					}
				})
			})
		}
	}
}
</script>

<style lang="less" scoped>
</style>