 <template>
    <div class="contain">
        <searchBar :searchInput="false"></searchBar>
        <div class="title bgf flex">
            <div class="fs_16 color_333 flex_start">
                <span>批次列表</span>
                <span class="badge">{{ total }}</span>
            </div>
            <div class="flex_c right">
                <!-- <div class="btn fs_14 flex_cen import-batch" @click="authPath([], bulkImport)">
                    <p class="import-icon "></p>批量指定合作方 <span class="tip">NEW</span>
                </div> -->
            </div>
        </div>
        <div class="padding bgf mt_10">
            <div style="min-height:441px">
                <el-table :data="tableData" style="width: 100%;" :row-style="rowStyle">
                    <el-table-column prop="sn" label="批次" width="110px">
                    </el-table-column>
                    <el-table-column prop="member_name" label="操作人员" width="100px">
                    </el-table-column>
                    <el-table-column prop="att_info" label="人员清单" width="200px" show-overflow-tooltip>
                        <template #default="scope">
                            <!-- <el-tooltip class="item" effect="dark" :content="scope.row.att_info" placement="top"> -->
                            <p class="color_666 one">{{ scope.row.att_info || '暂未上传人员清单' }}</p>
                            <!-- </el-tooltip> -->
                        </template>
                    </el-table-column>
                    <el-table-column prop="zip_info" label="身份证资料" width="200px">
                        <template #default="scope">
                            <p v-if="scope.row.zip_info">{{ scope.row.zip_info }}</p>
                            <p v-else @click="showDialog(scope.row.id)"><a href="javascript:void(0);" style="text-decoration: revert;color:#be230e">暂未上传身份证资料，点击上传！</a></p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="total" label="申请数量" width="100px">
                    </el-table-column>
                    <el-table-column prop="num" label="办理中 / 办理完结 / 已绑定" width="170px">
                    </el-table-column>
                    <el-table-column prop="create_time" label="添加时间" width="150px">
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="80px">
                        <template #default="scope">
                            <div class="color pointer" @click="getInfo(scope.row.id)">查看数据</div>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <div>
                            <p class="loading_" v-if="!finish">
                                <i class="el-icon-loading fs_20"></i> 努力加载中...
                            </p>
                            <p class="loading_" v-else>暂无数据</p>
                        </div>
                    </template>
                </el-table>
            </div>
            <div class="flex_cen mt_15">
                <el-pagination class="page" background :page-size="limit" :current-page="page" layout="prev, pager, next" :total="total" @current-change="load">
                </el-pagination>
            </div>
        </div>
        <empty v-if="tableData.length == 0 && !finish">
            <div class="flex_cen">
                <el-button size="medium" plain @click="authPath([], bulkImport)">立即批量导入</el-button>
            </div>
        </empty>
        <record-list ref="recordList"></record-list>

        <el-dialog title="身份证资料上传" v-model="dialogUploadVisible" width="520px" top="10vh">
            <el-upload ref="dataUpload" class="upload" accept=".zip,.rar" drag
            :auto-upload="false" :action="`${baseApi}/ent/v3/bussiness/batch/import/${import_id}`"
            :headers="headers" :limit="2"
            :on-change="onChange"
            :on-error="onError"
            :on-success="handleSuccess">
                <img src="@/assets/imgs/icon/upload-icon.png" class="icon" alt="">
                <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>
                    <div class="el-upload__tip">
                        上传文件格式仅支持 rar、zip，且文件大小不能超过200M
                    </div>
                </div>
            </el-upload>
            <div class="flex_cen tip-box">
                <div>
                    <p class="center bold">身份证资料打包示例</p>
                    <p class="fs_14 color_666 padding_10 mt_10">步骤一：将人员身份证正反面按【姓名-身份证-正 / 反】命名规则</p>
                    <img src="@/assets/imgs/tip-img.png" alt="" class="">
                    <p class="fs_14 color_666 padding_10 mt_10">步骤二：将此文件夹打包压缩为 rar / zip 格式，上传到平台</p>
                    <img src="@/assets/imgs/zip-tip.png" alt="" class="" style="width:16%">
                </div>
            </div>
            <div class="flex_cen">
                <el-button type="primary" class="next-btn" :disabled="!hasData" :loading="sandLoading" @click="submitUpload">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getToken } from '@/common/cache'
import recordList from './components/record-list'
export default {
	components: { recordList },
	data() {
		return {
			baseApi: process.env.VUE_APP_API,
			// baseApi: 'https://zlh.zhiluinfo.com',
			headers: {
				'Authorization': 'Bearer ' + getToken()
			},
			import_id: '',
			tableData: [],
			page: 1,
			limit: 10,
			total: 0,
			finish: false,
			dialogUploadVisible: false,
			sandLoading: false,

			hasData: false // 是否有选择文件
		}
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			this.$axios({
				url: '/ent/v3/bussiness/batch',
				data: {
					limit: this.limit,
					page: this.page
				},
				method: 'GET',
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.total = res.data.total
					res.data.list.find(item => {
						item.num = item.num.join(' / ')
					})
					this.tableData = res.data.list
					console.log(this.tableData.length >= res.data.total)
					this.finish = this.tableData.length >= res.data.total
				} else {
					this.finish = true
				}
			})
		},
		load(page) {
			this.page = page
			this.init()
		},
		showDialog(id) {
			this.import_id = id
			this.dialogUploadVisible = true
		},
		// 资料格式判断
		onChange(file, fileList) {
			let size = file.size / 1024 / 1024
			var FileExt = file.name.replace(/.+\./, "")
			if ([ 'zip', 'rar' ].indexOf(FileExt.toLowerCase()) === -1){
				this.$message.warning('请上传格式为 .zip 或 .rar 的文件')
				this.$refs.dataUpload.clearFiles()
				return false
			}
			if (size > 200) {
				this.$message.warning('文件大小不能超过200M')
				this.$refs.dataUpload.clearFiles()
				return false
			}
			if(fileList.length > 1) {
				fileList.shift()
			}
			this.hasData = true
		},

		submitUpload() {
			this.sandLoading = true
			this.$refs.dataUpload.submit()
		},
		handleSuccess(res) {
			this.sandLoading = false
			if (res.code === 200) {
				this.$message.success('上传成功')
				this.init()
			}else {
				this.$message.error(res.msg)
				this.$refs.dataUpload.clearFiles()
			}
			this.dialogUploadVisible = false
			setTimeout(()=>{
				this.hasData = false
			}, 600)
		},
		onError() {
			this.sandLoading = false
		},
		getInfo(id) {
			this.$refs.recordList.id = id
			this.$refs.recordList.init()
			this.$refs.recordList.dialogTableVisible = true
		},
		bulkImport() {
			this.$router.push('/bulkImport/bussiness')
		}
	}
}
</script>

<style lang="less" scoped>
.title {
    padding: 19px 38px 20px 54px;
    border-radius: 2px;
}
.tip-box {
    width: 460px;
    margin: 10px auto;
    border: 1px dashed #ccc;
    border-radius: 6px;
    padding: 20px 10px;
}
/deep/.upload {
    width: 460px;
    .icon {
        width: 42px;
        height: 33px;
        margin: 40px auto 5px;
    }
    .el-upload-dragger {
        width: 460px;
        height: 160px;
    }
    margin: 0 auto 0;
    .el-upload__tip {
        color: #a2aec7 !important;
    }
}
.next-btn {
        width: 200px;
        height: 42px;
        font-size: 16px;
        margin-bottom: -10px;
    }
</style>